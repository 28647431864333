html {
	width:100%;
	height:100%;
	margin:0px;
	padding:0px;

  /* Common Variables */
  --custom-purple: #1b0621;
  --text-color: #ffffff;
}

body{
	background-color: var(--custom-purple);
	background-size:cover;
	background-position:top;
	background-repeat:no-repeat;
	color: var(--text-color);
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	letter-spacing:2px;
	width:100%;
	height:100%;
	margin:0px;
	padding:0px;
}
@media (max-width:600px){
	body{
		font-size: 12px;
	}
}

a{
  display: inline-block;
	color: var(--text-color);
	text-decoration: underline;
}

h1{
	font-size:20px;
	text-transform:uppercase;
	margin:0px;
	font-weight: normal;
}

h2{
	font-size:16px;
	text-transform:uppercase;
	margin:0px;
	font-weight: normal;
}

p{
	margin-bottom:30px;
}

ul{
  -webkit-padding-start: 0px; 
  list-style-type: none;
  margin: 0px 0px 40px 0px; 
  padding: 30px 0px 0px 0px;
}

img{
  width: 100%;
}

iframe{
  width: 100%;
}

#container{
  width:100%;
}

/* HEADER */
header{
  padding: 15px 0;
  margin-bottom:60px;
}
@media(max-width:500px){
  header{
    margin-bottom:40px;
  }
}

/* CONTENT */
#content{
  max-width: 600px;
	margin: 0 auto;
	text-align:center;
	padding:0 15px;
}

.section{
  padding-bottom:40px;
}

/* FOOTER */
footer{
  text-align: center;
  padding: 30px 0;
}

/* Live dates */
  .date-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .date, .location, .link {
    flex: 1;
    text-align: left;
  }
  .location {
		flex: 0.8;
    /* text-align: center; */
  }
  .link {
		flex: 2;
    text-align: right;
  }